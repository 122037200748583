import React, { Fragment, useEffect, useRef } from 'react';
// import 'rc-time-picker/assets/index.css';
// import TimePicker from 'rc-time-picker';
import { Button } from 'reactstrap';
import Autosuggest from 'react-autosuggest';
import LoadingSpinner from '../../../../../utils/LoadingSpinner';
import { checkInternetConnectionWithBrowserNavigation } from '../../../../../utils/general'
import TimeKeeper from 'react-timekeeper';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import moment from 'moment';
// import {required} from '../../../../../utils/general';




// const getSuggestionValue = (suggestion) => suggestion
const getSuggestionValue = (suggestion) => suggestion.phone_no
const renderSuggestion = (suggestion) => (<span className="cursor-pointer">{suggestion.phone_no} {suggestion.fullname}</span>)

let FoodAppsCustomerInformationModalBodyForm = (props) => {
  // const format = 'h:mm a';
  // const now = moment().hour(0).minute(0);
  /* function onChange(value) {
      console.log('vlue of the TimePicker')
      console.log(value)
      console.log(value && value.format(format));
  } */

  const { initialValues, handelOnChangeTimeKeeper, isCustomerSavingLoader, inputAddressChangedHandler, query, toggle } = props;
  // const { initialValues,handelOnChangeTimePicker, handelOnChangeTimeKeeper , handleSubmit ,timeSelectionRadioBoxValue,isCustomerSavingLoader,inputAddressChangedHandler,query, toggle} = props;
  const { value, suggestions } = props;
  console.log('value in inputProps')
  console.log('value in inputProps')
  console.log('value in inputProps')
  console.log(value)
  let newValue = value;
  if (newValue !== '' && newValue !== null) {
    newValue = newValue.toString();
  }
  const inputProps = {
    placeholder: "Enter Phone#",
    value: newValue,
    onChange: props.onChangeAutoSuggestion
  };
  const isConnected = checkInternetConnectionWithBrowserNavigation();
  // console.log('props in cus')
  // console.log('props.selectedOrderType')
  // console.log(props.selectedOrderType)
  // console.log('props.card_processing_fee')
  // console.log(props.card_processing_fee)
  // console.log('props.time_picker_time')
  // console.log('props.time_picker_time')
  // console.log('props.time_picker_time')
  // console.log(props.time_picker_time)

  let CustomerDetailSchema = {};
  let objCustomerDetailSchema = {
    fullname: Yup.string().required('full Name is Required'),
    phone_no: Yup.string()
      .min(10, 'Phone No must be atleast of 10 numbers')
      .required('Phone No is Required'),
    // email: Yup.string()
    //     .email('Please provide a valid email')
    //     .required('Email is Required'),

  }
  CustomerDetailSchema = Yup.object().shape(objCustomerDetailSchema);
  // if(userData.isAuth){
  //   if(userData.security_question_answer === undefined || userData.security_question_answer === 'undefined' || userData.security_question_answer === ''){
  //     objCustomerDetailSchema.security_question_answer = Yup.string().min(3, 'Answer must be atleast of 3 characters').required('Answer is Required');
  //   }
  //   CustomerDetailSchema = Yup.object().shape(objCustomerDetailSchema);
  // }else{
  //   objCustomerDetailSchema.password = Yup.string().min(8, 'Password Too Short!').max(50, 'Password Too Long!').required('Password is Required');
  //   objCustomerDetailSchema.confirmPassword =Yup.string().min(8, 'Password Too Short!').max(50, 'Password Too Long!').oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required');
  //   objCustomerDetailSchema.security_question_answer = Yup.string().min(3, 'Answer must be atleast of 3 characters').required('Answer is Required');
  //   CustomerDetailSchema = Yup.object().shape(objCustomerDetailSchema);
  // }
  /* console.log('props.selectedOrderType')
  console.log('props.selectedOrderType')
  console.log('props.selectedOrderType')
  console.log(props.selectedOrderType) */
  // console.log('props.time_picker_time')
  // console.log('props.time_picker_time')
  // console.log(props.time_picker_time)
  // console.log(props.time_picker_time.toString())
  const validate = (values) => {
    // const validate = (values, props /* only available when using withFormik */) => {
    // console.log('props in validate')
    // console.log('props in validate')
    // console.log(props)
    let errors = {}
    console.log('values in validate')
    console.log('values in validate')
    console.log('values in validate')
    console.log(values)
    if (!values.fullname) {
      errors.fullname = 'This field is required.';
    }
    /* if (!values.email) {
      errors.email = 'This field is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    } */
    const isConnected = checkInternetConnectionWithBrowserNavigation();
    // if(!isConnected){
    // if (true) {
    //   if (!values.phone_no) {
    //     errors.phone_no = 'This field is required.';
    //   } else if (isNaN(Number(values.phone_no))) {
    //     errors.phone_no = 'Must be a number'
    //   } else if (!/^(0|[1-9][0-9]{9})$/i.test(values.phone_no)) {
    //     errors.phone_no = 'Invalid phone number, must be 10 digits';
    //   }
    // }
    // if ((!props.isMistakeActive || (props.isMistakeActive && props.mistake_type === 'existing'))) {
    //   if (!values.timeSelectionRadioBox) {
    //     errors.timeSelectionRadioBox = 'This field is required.';
    //   } else {
    //     if (values.timeSelectionRadioBox === "have_ready_in") {
    //       if (!values.drp_default_time) {
    //         errors.drp_default_time = 'This field is required.';
    //       }
    //     }
    //   }
    // }
    if (!isConnected) {
      if (!values.address) {
        errors.address = 'You must enter your address!'
      }
    }
    console.log('errors in validate')
    console.log('errors in validate')
    console.log('errors in validate')
    console.log('errors in validate')
    console.log(errors)
    /* if (!values.notes) {
      errors.notes = 'This field is required.';
    } */

    return errors
  }
  const myDefaultTimeChange = (drp_default_time) => {
    // console.log('myDefaultTimeChange')
    // console.log('myDefaultTimeChange')
    // console.log(drp_default_time)
    props.handleOnChangeDrfDefaultTime(drp_default_time);
    // this.setState({ suggestions: [] });
  };

  // const textInput = useRef(null);

  // useEffect(() => {
  //     textInput.current.focus();
  // }, []);
  return (
    <Fragment>
      <Formik
        // enableReinitialize={false}
        enableReinitialize
        initialValues={initialValues}
        // ref={props.customerDetailForm}
        // validationSchema={CustomerDetailSchema}
        validate={validate}
        onSubmit={props.onSubmitForm}
        render={({ values, errors, status, touched, setFieldValue }) => (
          <Form autoComplete="off">
            {/* {
              (props.isMistakeActive && props.mistake_type === 'new') || props.selectedOrderType === 'pickup' ?
                null
                :
                isConnected ?
                  <div className="form-group">
                    <input type="text" className="form-control" id="autocomplete" value={query}
                      onChange={
                        (event) => inputAddressChangedHandler(event)
                      } placeholder="Address online" />
                  </div>
                  :
                  <div className="form-group">
                    <Field placeholder="Address" name="address" type="text" className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')} />
                    <ErrorMessage name="address" component="div" className="invalid-feedback invalid-feedback-error" />
                  </div>
            } */}
            <div className="form-group">
              <Field placeholder="Name" autoComplete="off" name="fullname" type="text" className={'form-control' + (errors.fullname && touched.fullname ? ' is-invalid' : '')} />
              <ErrorMessage name="fullname" component="div" className="invalid-feedback invalid-feedback-error" />
            </div>
            <div className="form-group">
              <Field placeholder="Notes" name="notes" type="text" className={'form-control' + (errors.notes && touched.notes ? ' is-invalid' : '')} />
              <ErrorMessage name="notes" component="div" className="invalid-feedback invalid-feedback-error" />
            </div>
            {
              props.selectedOrderType === 'delivery' && props.card_processing_fee !== false ?
                <div className="form-group">
                  <div className="col-12">
                    <p className="text-center txtPayMethod">
                      How will you pay the delivery driver when they arrive?
                    </p>

                    <div className="row mt10">
                      <div className="col">
                        <button type="button" onClick={() => props.btnOnClickPaymentMethodType('cash')} className={`btn btn-lg btn-block bg-light-grey ${props.selectedDeliveryPaymentMethod === 'cash' ? 'btnSelectedPaymentMethod' : ''}  `}><i className="far fa-money-bill-alt"></i> Cash </button>
                      </div>
                      <div className="col">
                        <button type="button" onClick={() => props.btnOnClickPaymentMethodType('credit')} className={`btn btn-lg btn-block bg-light-grey ${props.selectedDeliveryPaymentMethod === 'credit' ? 'btnSelectedPaymentMethod' : ''}  `}><i className="fa fa-credit-card"></i> Debit/Credit </button>
                      </div>
                    </div>
                  </div>
                </div>
                : null
            }
            {/* {
              (!props.isMistakeActive || (props.isMistakeActive && props.mistake_type === 'existing')) ?
                <Fragment>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <div className="form-check form-check-inline">
                          <Field
                            name="timeSelectionRadioBox"
                            // component="input"
                            type="radio"
                            value="have_ready_in"
                            checked={values.timeSelectionRadioBox === "have_ready_in"}
                          />
                          <ErrorMessage name="timeSelectionRadioBox" component="div" className="invalid-feedback invalid-feedback-error" />
                          <label className="form-check-label" >Have Ready In</label>
                        </div>
                        <div className="form-group">
                          <Field
                            name="drp_default_time"
                            className="form-control"
                            component="select"
                            onChange={e => {
                              setFieldValue("drp_default_time", e.target.value);
                              myDefaultTimeChange(e.target.value);
                            }}
                          // onChange={e => setFieldValue('drp_default_time', e)}
                          >
                            <option value="">Select Time</option>
                            <option value="5">5 Minutes</option>
                            <option value="10">10 Minutes</option>
                            <option value="15">15 Minutes</option>
                            <option value="30">30 Minutes</option>
                            <option value="45">45 Minutes</option>
                          </Field>
                          <ErrorMessage name="drp_default_time" component="div" className="invalid-feedback invalid-feedback-error" />
                        </div>
                      </div>

                    </div>
                    <div className="col">
                      <div className="form-group">
                        <div className="form-check form-check-inline">
                          <Field
                            name="timeSelectionRadioBox"
                            // component="input"
                            type="radio"
                            value="other_time"
                            checked={values.timeSelectionRadioBox === "other_time"}
                          />
                          <ErrorMessage name="timeSelectionRadioBox" component="div" className="invalid-feedback invalid-feedback-error" />
                          <label className="form-check-label" >Other</label>
                        </div>
                      </div>
                      <div className="form-group">
                        <TimeKeeper
                          // time={props.existing_order_id !== '' ? {hour: props.time_picker_time.hours(),minute: props.time_picker_time.minutes()} : false}
                          time={props.time_picker_time === null ? false : props.existing_order_id !== '' ? { hour: props.time_picker_time.hours(), minute: props.time_picker_time.minutes() } : { hour: props.time_picker_time.hours(), minute: props.time_picker_time.minutes() }}
                          // onChange={handelOnChangeTimeKeeper}
                          onChange={(data) => {
                            handelOnChangeTimeKeeper(data);
                            setFieldValue("timeSelectionRadioBox", "other_time");
                            props.handleOnChangetimeSelectionRadioBox("other_time")
                          }}
                        />
                      </div>
                    </div>
                  </div>

                </Fragment>
                : null
            } */}

            {
              isCustomerSavingLoader === false ?
                <div className="text-right">
                  <Button type="submit" color="warning" className="btnCustomerModalButton" >Done</Button>
                  <Button color="warning" className="btnCustomerModalButton" onClick={toggle}>Cancel</Button>
                </div>
                : <div className="text-center"><LoadingSpinner subClass={'mt10 mb10'} /></div>
            }
          </Form>
        )}
      />

    </Fragment>
  );
}






export default FoodAppsCustomerInformationModalBodyForm