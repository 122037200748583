import React,{Fragment} from 'react';
// import { formatExistingOrderDateTimeOnly } from '../../../../../utils/general';
import { ShowDeliveryPickupTimeOnReceipt } from '../../../../../utils/helpers/timeHelpers';
const RenderSelectedDeliveryType = (props) => {
    let DeliveryDiv = '';
    if(props.selectedOrderType === 'pickup'){
        // if(props.existing_order_id !== '' && props.selectedExistingOrder.created_from === 'web' && props.selectedOrderType === 'pickup' ){
        // if(props.selectedOrderType === 'pickup' ){
            DeliveryDiv = 
            <Fragment>
                <h6>Pickup </h6>
                {
                    Object.keys(props.objCustomer).length > 0 ?
                    <h6 className="spanPickupMobileTime text-danger"> {ShowDeliveryPickupTimeOnReceipt(props.objCustomer)}</h6>
                    :null
                }
                {/* <h6 className="spanPickupMobileTime text-danger"> {ShowDeliveryPickupTimeOnReceipt(props.objCustomer,props.selectedExistingOrder.order_completion_datetime)}</h6> */}
            </Fragment>
        // }else{
        //     DeliveryDiv =  <h6>Pickup</h6>;
        // }
    }else if(props.selectedOrderType === 'delivery'){
        DeliveryDiv = 
        <Fragment>
            <h6>Delivery</h6>
            {
                Object.keys(props.objCustomer).length > 0 ?
                <h6 className="spanPickupMobileTime text-danger"> {ShowDeliveryPickupTimeOnReceipt(props.objCustomer)}</h6>
                :null
            }
            {/* <h6 className="spanPickupMobileTime text-danger"> {ShowDeliveryPickupTimeOnReceipt(props.objCustomer,props.selectedExistingOrder.order_completion_datetime)}</h6> */}
        </Fragment>
    }else if(props.selectedOrderType === 'dine_in'){
        DeliveryDiv = <h6>Dine-In</h6>;
    }else if(props.selectedOrderType === 'takeout'){
        DeliveryDiv = <h6>TakeOut</h6>;
    }else if(props.selectedOrderType === 'food_apps'){
        DeliveryDiv = <h6>Food-Apps</h6>;
    }

    return DeliveryDiv;
    // return (
    //     <h6>{Delivery}</h6>       
    // );
};

export default RenderSelectedDeliveryType;