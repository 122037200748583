import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import IsNewOrExistingCustomerSelectionModalBody from './IsNewOrExistingCustomerSelectionModalBody';
class IsNewOrExistingCustomerSelectionModal extends React.Component {
    constructor(props) {
        super(props);
        /* this.state = {
            modal: props.showIsNewExistingCustomerSelectionModal
        }; */
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        /* this.setState({
            modal: !this.props.showIsNewExistingCustomerSelectionModal
            // modal: !this.state.modal
        }); */
        this.props.handleOnChangeNewOrExistingCustomerModalToggle(!this.props.showIsNewExistingCustomerSelectionModal)
        /* this.setState(prevState => ({
            modal: !prevState.modal
        })); */
    }
    
    render() {
        /* console.log('this.state.modal in IsNewOrExistingCustomerSelectionModal')
        console.log(this.state.modal)
        console.log('this.props.showIsNewExistingCustomerSelectionModal')
        console.log(this.props.showIsNewExistingCustomerSelectionModal) */
        let ModalheaderTxt = "";
        if(this.props.selectedOrderType === 'delivery'){
            ModalheaderTxt = 'Select delivery type';
        }else if(this.props.selectedOrderType === 'pickup'){
            ModalheaderTxt = 'Select pickup type';
        }else if(this.props.selectedOrderType === 'food_apps'){
            ModalheaderTxt = 'Select Food Apps type';
        }else{
            ModalheaderTxt = 'Select Customer Type';
        }
        return (
                <Modal isOpen={this.props.showIsNewExistingCustomerSelectionModal} toggle={this.toggle} className={this.props.className} >
                    <ModalHeader toggle={this.toggle}>
                        {ModalheaderTxt}
                    </ModalHeader>
                    <ModalBody>
                        <IsNewOrExistingCustomerSelectionModalBody 
                            handleOnClickCustomerTypeSelection={this.props.handleOnClickCustomerTypeSelection}
                        />
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
        );
    }
};

export default IsNewOrExistingCustomerSelectionModal;