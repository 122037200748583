import React from 'react';
// import 1Png from '../../../../common/images/icons/1.png';
import onePng from '../../../../common/images/icons/1.png'
import TwoPng from '../../../../common/images/icons/2.png'
import threePng from '../../../../common/images/icons/3.png'
import fourthPng from '../../../../common/images/icons/4.png'
const OrderTypes = (props) => {



  return (
    <ul className="d-flex align-items-center">
      <li>
        <button
          className={`btn btn-danger w-100 py-2 d-inline-flex align-items-center justify-content-center btnUpperMenu ${props.selectedOrderType === 'pickup' ? 'btnActiveOrderType' : ''} `} onClick={() => props.handleOnClickOrderType('pickup')}>
          <p>
            <img src={onePng} alt="icons" className="mw-100 pr-3" />
            Pickup
          </p>
        </button>
      </li>
      <li>
        <button
          className={`btn btn-danger w-100 py-2 d-inline-flex align-items-center justify-content-center btnUpperMenu ${props.selectedOrderType === 'delivery' ? 'btnActiveOrderType' : ''} `} onClick={() => props.handleOnClickOrderType('delivery')}>
          <p>
            <img src={TwoPng} alt="icons" className="mw-100 pr-3" />
            delivery
          </p>
        </button>
      </li>
      <li>
        <button
          className={`btn btn-danger w-100 py-2 d-inline-flex align-items-center justify-content-center btnUpperMenu ${props.selectedOrderType === 'dine_in' ? 'btnActiveOrderType' : ''} `} onClick={() => props.handleOnClickOrderType('dine_in')}>
          <p>
            <img src={threePng} alt="icons" className="mw-100 pr-3" />
            dine-in
          </p>
        </button>
      </li>
      <li>
        <button
          className={`btn btn-danger w-100 py-2 d-inline-flex align-items-center justify-content-center btnUpperMenu ${props.selectedOrderType === 'takeout' ? 'btnActiveOrderType' : ''} `} onClick={() => props.handleOnClickOrderType('takeout')}>
          <p>
            <img src={fourthPng} alt="icons" className="mw-100 pr-3" />
            takeout
          </p>
        </button>
      </li>
      <li>
        <button
          className={`btn btn-danger w-100 py-2 d-inline-flex align-items-center justify-content-center btnUpperMenu ${props.selectedOrderType === 'food_apps' ? 'btnActiveOrderType' : ''} `} onClick={() => props.handleOnClickOrderType('food_apps')}>
          <p>
            <img src={fourthPng} alt="icons" className="mw-100 pr-3" />
            Food-Apps
          </p>
        </button>
      </li>

    </ul>
  );
};

export default OrderTypes;