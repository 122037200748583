import React from 'react';
const PosOrderPrintRenderSelectedDeliveryType = (props) => {
    let Delivery = '';
    if(props.selectedOrderType === 'pickup'){
        Delivery = 'Pickup';
    }else if(props.selectedOrderType === 'delivery'){
        Delivery = 'Delivery';
    }else if(props.selectedOrderType === 'dine_in'){
        Delivery = 'Dine-In';
    }else if(props.selectedOrderType === 'takeout'){
        Delivery = 'TakeOut';
    }else if(props.selectedOrderType === 'food_apps'){
        Delivery = 'Food-Apps';
    }
  
    return (
        <p className="OrderDelivery">{Delivery}</p>       
    );
};

export default PosOrderPrintRenderSelectedDeliveryType;