import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import FoodAppsExistingOrdersModalBody from './FoodAppsExistingOrdersModalBody';
class FoodAppsExistingOrdersModal extends React.Component {
  constructor(props) {
    super(props);
    /* this.state = {
        modal: props.showExistingFoodAppsOrdersModal
    }; */
    this.toggle = this.toggle.bind(this);
  }

  componentWillUnmount() {
    // console.log('componet unmount')
    // this.props.clearExistingFoodAppsOrderHistory();
    // this.props.clearExistingFoodAppsOrderLimits();
  }

  toggle() {
    this.props.handleOnChangeFoodAppsExistingOrderModalToggle(!this.props.showExistingFoodAppsOrdersModal);
    this.props.clearExistingFoodAppsOrderHistory();
    this.props.clearExistingFoodAppsOrderLimits();
  }

  render() {
    /* console.log('this.state.modal in FoodAppsExistingOrdersModal')
    console.log(this.state.modal)
    console.log('this.props.showExistingFoodAppsOrdersModal')
    console.log(this.props.showExistingFoodAppsOrdersModal) */
    // console.log('this.props.limitExstingOrders')
    // console.log('this.props.limitExstingOrders')
    // console.log(this.props.limitExstingOrders)

    return (
      <Modal isOpen={this.props.showExistingFoodAppsOrdersModal} toggle={this.toggle} className={this.props.className} size="lg">
        <ModalHeader toggle={this.toggle}>Food-Apps Existing Orders</ModalHeader>
        <ModalBody>
          <FoodAppsExistingOrdersModalBody
            order={this.props.order}
            onClickHandleLoadMoreExistingFoodAppOrderItems={this.props.onClickHandleLoadMoreExistingFoodAppOrderItems}
            limitExstingOrders={this.props.limitExstingOrders}
            isloadingExistingFoodAppsOrders={this.props.isloadingExistingFoodAppsOrders}
            handelOnClickExistingFoodAppsOrder={this.props.handelOnClickExistingFoodAppsOrder}
          />
        </ModalBody>
        {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
      </Modal>
    );
  }
};

export default FoodAppsExistingOrdersModal;