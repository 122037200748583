import React,{Fragment} from 'react';
import OrderItemPosKitchenCarouselBlock from './OrderItemPosKitchenCarouselBlock';
import KitchenCarouselOrderMenuOptionsModal from './KitchenCarouselOrderMenuOptionsModal/KitchenCarouselOrderMenuOptionsModal';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import moment from 'moment';
const LoadMorePosKitchenCarouselOrderItems = (props) => {
    // sort them in ascending order
    // let Orders = props.Orders.sort((a, b) => a.order_completion_datetime.localeCompare(b.order_completion_datetime));
    let Orders = props.Orders.sort((a, b) => {
        // You can check if your value is null then you can assign blank using pipe.
        a.order_completion_datetime = a.order_completion_datetime || '';
        b.order_completion_datetime = b.order_completion_datetime || '';
        // console.log('a')
        // console.log(a)
        // console.log('b')
        // console.log(b)
        return a.order_completion_datetime.localeCompare(b.order_completion_datetime)
    });
    Orders.map(o => {
        // let currentTime = moment().format("hh:mm:ss a");
        // let endTime = moment(o.order_completion_datetime).format("hh:mm:ss a");

        // // currentTime = moment(currentTime, "hh:mm:ss a");
        // // endTime = moment(endTime, "hh:mm:ss a");
        // console.log('currentTime')
        // console.log(currentTime)
        // console.log('endTime')
        // console.log(endTime)
        // // let mins = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(currentTime, "HH:mm:ss"))).format("mm")
        // let mins = moment.utc(moment(currentTime, "HH:mm:ss").diff(moment(endTime, "HH:mm:ss"))).format("mm")
        // // let TotalHours = endTime.diff(currentTime, 'hours') + " Hrs and " + mins + " Mns";
        // console.log('mins')
        // console.log(mins)

        // Example
        // var start = moment();
        // console.log(start.format("hh:mm a"))
        // var  end = moment('2019-08-06T15:09:22.529Z');
        // console.log(end.format("hh:mm a"))
        // end.diff(start, 'minutes') // 1
        let start = moment();
        // console.log(start.format("hh:mm a"))
        let  end = moment(o.order_completion_datetime);
        // console.log(end.format("hh:mm a"))
        let mins = end.diff(start, 'minutes') 
        console.log('mins')
        console.log(mins)
        if(mins > 30){
            o.isMoreThan30Mins = true;
            o.isMoreThan30MinsClass = 'orderMoreThan30Mins';
        }else if((mins === 0 || mins < 0 || mins <= 5) && (o.kitchen_status !== 'done') && (o.delivery_type === 'pickup' || o.delivery_type === 'delivery')){
            console.log('i im last if')
            o.isLate = true;
            if(o.status === 'cash' || o.status === 'debit_card' || o.status === 'credit_card' || o.status === 'gift_card'){
                o.isLateClass = 'orderLateCashedThrough';
            }else{
                o.isLateClass = 'orderLate';
            }
            o.isMoreThan30Mins = false;
            o.isMoreThan30MinsClass = '';
        }else{
            o.isLate = false;
            o.isLateClass = '';
            o.isMoreThan30Mins = false;
            o.isMoreThan30MinsClass = '';
        }
        // console.log('o.isLate')
        // console.log(o.isLate)
        console.log('o.isLateClass')
        console.log(o.isLateClass)
        console.log('o.isMoreThan30Mins')
        console.log(o.isMoreThan30Mins)
        // console.log("endTime.diff(currentTime, 'hours')")
        // console.log(endTime.diff(currentTime, 'hours'))
        // if (selected.includes(o.id)) {
        //   o.checked = true;
        // }
        return o;
    });
    const  dineineTakeoutOrders = Orders.filter(items=> (items.delivery_type === 'takeout' || items.delivery_type === 'dine_in'));
    // menuItemWithIngredient.Ingredients.filter(ingredient => ingredient.type === "sauces");
    const  pickupDeliveryOrders = Orders.filter(items => (items.delivery_type === 'pickup' || items.delivery_type === 'delivery' || items.delivery_type === 'food_apps'));
    return (
        <Fragment>
            <div className="w-100">
                {/* <div className="row w-100"> */}
                <OrderItemPosKitchenCarouselBlock
                    list={dineineTakeoutOrders}
                    // list={props.Orders}
                    comboItemColor={props.comboItemColor}
                    onClickHandleKitchenOrder={props.onClickHandleKitchenOrder}
                    showItems={props.showItems}
                    // handelOnClickPosOrderPrint={props.handelOnClickPosOrderPrint}
                />
                {/* <hr/> */}
                {/* <hr/> */}
                {/* <hr/> */}
                <OrderItemPosKitchenCarouselBlock
                    list={pickupDeliveryOrders}
                    // list={props.Orders}
                    comboItemColor={props.comboItemColor}
                    onClickHandleKitchenOrder={props.onClickHandleKitchenOrder}
                    showItems={props.showItems}
                    // handelOnClickPosOrderPrint={props.handelOnClickPosOrderPrint}
                />
                <KitchenCarouselOrderMenuOptionsModal 
                    handleOnChangeKitchenOrderMenuOptionsModalToggle={props.handleOnChangeKitchenOrderMenuOptionsModalToggle}
                    showKitchenOrderMenuOptionsModal={props.showKitchenOrderMenuOptionsModal}
                    handleOnClickDoneOrder={props.handleOnClickDoneOrder}
                    handleOnClickClearOrder={props.handleOnClickClearOrder}
                />
                {Orders ?
                    Orders.length === 0 ?
                        <div className="col-12 no_result">
                            <p className="text-center text-white"> You have not placed any orders yet.</p>
                        </div>
                        : null
                    : null
                }
                
            </div>
            
            {
                props.isloadingPosKitchenOrders ? <LoadingSpinner subClass={'mt10 mb10'} /> :null
            }
            {
                props.total_pos_kitchen_orders > 0 && props.total_pos_kitchen_orders > props.Orders.length ?
                    <div>
                        <div className="text-center mt20 pb20">
                            <button className="btn btn-light-success text-white btnPrint btnLoadMore btnLoadMoreKitchenOrders"  onClick={()=> props.onClickHandleLoadMorePosKitchenOrderItems()}>Load More Orders</button>
                        </div> 
                    </div>
                :null
            }
           
        </Fragment>
    );
};

export default LoadMorePosKitchenCarouselOrderItems;