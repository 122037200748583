import React, { Fragment } from 'react';
import FoodAppsExistingOrderItemBlock from './FoodAppsExistingOrderItemBlock';

const LoadMoreFoodAppsExistingOrderItems = (props) => {
  return (
    <Fragment>
      <FoodAppsExistingOrderItemBlock
        list={props.Orders}
        onClickHandleLoadMoreExistingFoodAppOrderItems={props.onClickHandleLoadMoreExistingFoodAppOrderItems}
        handelOnClickExistingFoodAppsOrder={props.handelOnClickExistingFoodAppsOrder}
      />
      {
        props.total_food_apps_existing_orders > 0 && props.total_food_apps_existing_orders > props.Orders.length ?
          <tr className="load_more_container text-center">
            <td colSpan="4">
              <div className="cursor-pointer" onClick={() => props.onClickHandleLoadMoreExistingFoodAppOrderItems()}><i className="fas fa-angle-down show-more"></i></div>
            </td>
            {/* <span onClick={()=> props.loadMore()}>
                            Load More
                        </span> */}
          </tr>
          : null
      }



    </Fragment>
  );
};

export default LoadMoreFoodAppsExistingOrderItems;