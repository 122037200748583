import {
    SAVE_ORDER,
    EDIT_ORDER,
    FETCH_EXISTING_ORDERS,
    FETCH_ORDER_BY_ID,
    CLEAR_ORDER_HISTORY,
    CLEAR_EXISTING_ORDERS_HISTORY,
    FETCH_MAX_ORDER_TICKET_NUMBER,
    FETCH_POS_ORDERS,
    FETCH_EXISTING_DELIVERY_ORDERS,
    CLEAR_POS_ORDERS_HISTORY,
    FETCH_MISTAKE_EXISTING_ORDERS,
    CLEAR_MISTAKE_EXISTING_ORDERS_HISTORY,
    FETCH_POS_KITCHEN_ORDERS,
    CLEAR_POS_KITCHEN_ORDERS_HISTORY,
    CHANGE_POS_KITCHEN_ORDER_STATUS,
    ADD_NEW_POS_ORDER_TO_KITCHEN_DISPLAY,
    FETCH_WEB_EXISTING_ORDERS,
    CLEAR_WEB_EXISTING_ORDERS_HISTORY,
    UPDATE_POS_ORDER_TO_KITCHEN_DISPLAY,
    FETCH_WEB_ORDER_NOTIFICATIONS_COUNT,
    FETCH_TABLET_ORDER_NOTIFICATIONS_COUNT,
    FETCH_POS_TODAY_ORDERS,
    FETCH_TODAY_EXISTING_ORDERS,
    FETCH_TODAY_MISTAKE_EXISTING_ORDERS,
    FETCH_ALL_POS_KITCHEN_ORDERS,
    DELETE_ORDER_BY_ID,
    DELETE_POS_ORDER_FROM_KITCHEN_DISPLAY,
    DELETE_POS_ORDER_FROM_POS_ORDERS_PAGE,
    FETCH_NEW_TABLET_NOTIFICATION_ORDERS,
    FETCH_EXISTING_TABLET_NOTIFICATION_ORDERS,
    CLEAR_NEW_TABLET_NOTIFICATION_ORDERS_HISTORY,
    CLEAR_EXISTING_TABLET_NOTIFICATION_ORDERS_HISTORY,
    FETCH_FOOD_APPS_EXISTING_ORDERS,
    CLEAR_EXISTING_FOOD_APPS_ORDERS_HISTORY,
} from '../actions/types';
 

export default function(state={},action){
    switch(action.type){
        
        case SAVE_ORDER:
            return {...state,orderSavedSuccess:action.payload};
        case EDIT_ORDER:
            return {...state,orderEditedSuccess:action.payload};
        case DELETE_ORDER_BY_ID:
            return {...state,orderDeleteSuccess:action.payload};
        case FETCH_EXISTING_ORDERS:
            return  {...state,
                        existingOrders: action.payload.Orders,
                        total_existing_orders: action.payload.total_existing_orders
                    }
        case CLEAR_EXISTING_ORDERS_HISTORY:
            return {...state, existingOrders: action.payload,total_existing_orders:0 }
        case FETCH_FOOD_APPS_EXISTING_ORDERS:
            return  {...state,
                        existingFoodAppsOrders: action.payload.Orders,
                        total_food_apps_existing_orders: action.payload.total_food_apps_existing_orders
                    }
        case CLEAR_EXISTING_FOOD_APPS_ORDERS_HISTORY:
            return {...state, existingFoodAppsOrders: action.payload,total_food_apps_existing_orders:0 }
        case FETCH_MISTAKE_EXISTING_ORDERS:
            return  {...state,
                        mistakeExistingOrders: action.payload.Orders,
                        total_mistake_existing_orders: action.payload.total_mistake_existing_orders
                    }
        case CLEAR_MISTAKE_EXISTING_ORDERS_HISTORY:
            return {...state, mistakeExistingOrders: action.payload,total_mistake_existing_orders:0 }
        case FETCH_WEB_EXISTING_ORDERS:
            return  {...state,
                        webExistingOrders: action.payload.Orders,
                        total_web_existing_orders: action.payload.total_web_existing_orders
                    }
        case CLEAR_WEB_EXISTING_ORDERS_HISTORY:
            return {...state, webExistingOrders: action.payload,total_web_existing_orders:0 }
        case FETCH_ORDER_BY_ID:
            return  {...state,
                        Order: action.payload.order
                    }
        case FETCH_WEB_ORDER_NOTIFICATIONS_COUNT:
            return  {...state,
                        WebOrderNotificationsCount: action.payload.order_notifications_count
                    }
        case FETCH_TABLET_ORDER_NOTIFICATIONS_COUNT:
            return  {...state,
                        TabletOrderNotificationsCount: action.payload.tablet_order_notifications_count
                    }
        case CLEAR_ORDER_HISTORY:
            return {...state, existingOrders: action.payload,total_existing_orders:0 }
        case FETCH_MAX_ORDER_TICKET_NUMBER:
            return {...state, maxTicketNumber: action.payload.ticket_no }
        case FETCH_POS_ORDERS:{
            return  {...state,
                        posOrders: action.payload.Orders,
                        total_pos_orders: action.payload.total_pos_orders
                    }
        }
        case CLEAR_POS_ORDERS_HISTORY:
            return {...state, posOrders: action.payload,total_pos_orders:0 }
        case FETCH_POS_KITCHEN_ORDERS:
                /* console.log('...state')
                console.log('...state')
                console.log('...state')
                console.log(state) */
                return  {...state,
                        posKitchenOrders: action.payload.Orders,
                        total_pos_kitchen_orders: action.payload.total_pos_kitchen_orders
                    }
        case CLEAR_POS_KITCHEN_ORDERS_HISTORY:
            return {...state, posKitchenOrders: action.payload,total_pos_kitchen_orders:0 }
        case CHANGE_POS_KITCHEN_ORDER_STATUS:{
            // console.log('state')
            // console.log(state)
            let order_id = action.payload.order_id;
            let objOrder = action.payload.objOrder;
            let items = state.posKitchenOrders.slice();
            let total_pos_kitchen_orders = state.total_pos_kitchen_orders;
            const itemIndex = items.findIndex(item => item._id === order_id);
            /* console.log('itemIndex')
            console.log(itemIndex)
            console.log('objOrder.kitchen_status')
            console.log(objOrder) */
            // console.log(objOrder.kitchen_status)
            if(itemIndex !== -1){
                items[itemIndex].kitchen_status = objOrder.kitchen_status;  
                if(objOrder.kitchen_status === 'clear'){
                    items = items.filter((item, index) => index !== itemIndex);
                    total_pos_kitchen_orders = total_pos_kitchen_orders - 1;
                }
            }
            return {...state,
                posKitchenOrders: items,
                total_pos_kitchen_orders
            }
        }
        case ADD_NEW_POS_ORDER_TO_KITCHEN_DISPLAY:{
            let order = action.payload;
            let items = state.posKitchenOrders.slice();
            let total_pos_kitchen_orders = state.total_pos_kitchen_orders;
            const itemIndex = items.findIndex(item => item._id === order._id);
            console.log('itemIndex')
            console.log(itemIndex)
            // console.log('order.kitchen_status')
            // console.log(order)
            // console.log(objOrder.kitchen_status)
            if(itemIndex === -1){
                items.push(order)
                total_pos_kitchen_orders = total_pos_kitchen_orders + 1;
            }
            return {...state,
                posKitchenOrders: items,
                total_pos_kitchen_orders
            }
        }
        case UPDATE_POS_ORDER_TO_KITCHEN_DISPLAY:{
            let objOrder = action.payload;
            let items = state.posKitchenOrders.slice();
            let total_pos_kitchen_orders = state.total_pos_kitchen_orders;
            const itemIndex = items.findIndex(item => item._id === objOrder._id);
            /* console.log('itemIndex')
            console.log(itemIndex)
            console.log('objOrder.kitchen_status')
            console.log(objOrder) */
            // console.log(objOrder.kitchen_status)
            if(itemIndex !== -1){
                items = items.filter((item, index) => index !== itemIndex);
                items.push(objOrder);
            }
            return {...state,
                posKitchenOrders: items,
                total_pos_kitchen_orders
            }
        }
        case FETCH_POS_TODAY_ORDERS:
            return  {...state,
                        todayPosOrders: action.payload.Orders,
                        total_pos_today_orders: action.payload.total_pos_today_orders
                    }
        case FETCH_TODAY_EXISTING_ORDERS:
            return  {...state,
                        todayExistingOrders: action.payload.Orders,
                        total_today_existing_orders: action.payload.total_today_existing_orders
                    }
        case FETCH_TODAY_MISTAKE_EXISTING_ORDERS:
            return  {...state,
                        todayMistakeExistingOrders: action.payload.Orders,
                        total_all_mistake_existing_orders: action.payload.total_all_mistake_existing_orders
                    }
        case FETCH_ALL_POS_KITCHEN_ORDERS:
                /* console.log('state in FETCH_ALL_POS_KITCHEN_ORDERS')
                console.log('state in FETCH_ALL_POS_KITCHEN_ORDERS')
                console.log('state in FETCH_ALL_POS_KITCHEN_ORDERS')
                console.log(state) */
            return  {...state,
                    allPosKitchenOrders: action.payload.Orders,
                    total_all_pos_kitchen_orders: action.payload.total_all_pos_kitchen_orders
                }
        case DELETE_POS_ORDER_FROM_KITCHEN_DISPLAY:{
            
            // console.log('state')
            // console.log(state)
            let order_id = action.payload;
            let items = state.posKitchenOrders.slice();
            let total_pos_kitchen_orders = state.total_pos_kitchen_orders;
            const itemIndex = items.findIndex(item => item._id === order_id);
            
            if(itemIndex !== -1){
                items = items.filter((item, index) => index !== itemIndex);
                total_pos_kitchen_orders = total_pos_kitchen_orders - 1;
            }
            return {...state,
                posKitchenOrders: items,
                total_pos_kitchen_orders
            }
        }
        case DELETE_POS_ORDER_FROM_POS_ORDERS_PAGE:{
            
            // console.log('state')
            // console.log(state)
            let order_id = action.payload;
            let items = state.posOrders.slice();
            let total_pos_orders = state.total_pos_orders;
            const itemIndex = items.findIndex(item => item._id === order_id);
           
            if(itemIndex !== -1){
                items = items.filter((item, index) => index !== itemIndex);
                total_pos_orders = total_pos_orders - 1;
            }
            return {...state,
                posOrders: items,
                total_pos_orders
            }
        }
        case FETCH_NEW_TABLET_NOTIFICATION_ORDERS:{
            return  {...state,
                        newTabletNotificationOrders: action.payload.Orders,
                        total_new_tablet_notification_orders: action.payload.total_new_tablet_notification_orders
                    }
        }
        case FETCH_EXISTING_TABLET_NOTIFICATION_ORDERS:{
            return  {...state,
                        existingTabletNotificationOrders: action.payload.Orders,
                        total_existing_tablet_notification_orders: action.payload.total_existing_tablet_notification_orders
                    }
        }
        case CLEAR_NEW_TABLET_NOTIFICATION_ORDERS_HISTORY:{
            return { ...state, newTabletNotificationOrders: action.payload, total_new_tablet_notification_orders: 0 }
        }
        case CLEAR_EXISTING_TABLET_NOTIFICATION_ORDERS_HISTORY:{
            return { ...state, existingTabletNotificationOrders: action.payload, total_existing_tablet_notification_orders: 0 }
        }
        case FETCH_EXISTING_DELIVERY_ORDERS:{
            return  {...state,
                        objExistingDeliveryOrders: action.payload.Orders,
                    }
        }
        default:
            return state;
    }
}