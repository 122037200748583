import React from 'react';
const RenderDeliveryType = (props) => {
    let Delivery = '';
    if(props.selectedOrderType === 'pickup'){
        Delivery = 'Pickup';
    }else if(props.selectedOrderType === 'delivery'){
        Delivery = 'Delivery';
    }else if(props.selectedOrderType === 'dine_in'){
        Delivery = 'Dine-In';
    }else if(props.selectedOrderType === 'takeout'){
        Delivery = 'TakeOut';
    }else if(props.selectedOrderType === 'food_apps'){
        Delivery = 'Food-Apps';
    }
  
    return (
        <h6>{Delivery}</h6>       
    );
};

export default RenderDeliveryType;