import React, { Fragment } from 'react';
import FoodAppsOrderItem from './FoodAppsOrderItem';
import { withRouter } from 'react-router-dom';

const FoodAppsExistingOrderItemBlock = (props) => {

  const renderOrders = () => (
    props.list ?
      props.list.map(card => (
        <FoodAppsOrderItem
          key={card._id}
          handelOnClickExistingFoodAppsOrder={props.handelOnClickExistingFoodAppsOrder}
          {...card}
        />
      ))
      : null
  )

  return (
    <Fragment>
      {props.list ?
        props.list.length === 0 ?
          <tr className="no_result text-center">
            <td colSpan="4"> You have not placed any orders yet.</td>
          </tr>
          : null
        : null}
      {renderOrders(props.list)}

    </Fragment>
  );
};

export default withRouter(FoodAppsExistingOrderItemBlock);