import React, { Fragment } from 'react';
import { formatExistingOrderDateTimeOnly, formatExistingOrderDateTime, isDateMatches } from '../../../../../utils/general';
import RenderDeliveryType from './RenderDeliveryType';
const FoodAppsOrderItem = (props) => {


  // console.log('props in FoodAppsOrderItem')
  // console.log(props)
  return (
    <tr className="cursor-pointer" onClick={() => props.handelOnClickExistingFoodAppsOrder(props)}>
      <td>
        <RenderDeliveryType
          selectedOrderType={props.delivery_type}
        />
      </td>
      <td>
        {props.fullname}
      </td>
      <td>
        {/* {formatExistingOrderDateTime(props.order_completion_datetime)} <br /> */}
        {/* {formatExistingOrderDate(props.order_completion_datetime)}<br /> */}
        {formatExistingOrderDateTimeOnly(props.order_completion_datetime)} <br />
        {
          isDateMatches(props.order_completion_datetime, new Date()) === false ?
            <Fragment> {formatExistingOrderDateTime(props.order_completion_datetime)} </Fragment>
            : null
        }

      </td>
      <td>
        ${props.final_total}
      </td>

    </tr>
  );
}



export default FoodAppsOrderItem;