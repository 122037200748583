import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import FoodAppsExistingsOrderMenuOptionsModalBody from './FoodAppsExistingsOrderMenuOptionsModalBody';
class FoodAppsExistingsOrderMenuOptionsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discount: ''
      // modal: props.showFoodAppsExistingOrderMenuOptionsModal

    };

    this.toggle = this.toggle.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  toggle() {
    this.props.handleOnChangeFoodAppsExistingOrderMenuOptionsModalToggle(!this.props.showFoodAppsExistingOrderMenuOptionsModal)
  }

  render() {

    // console.log('this.props.showFoodAppsExistingOrderMenuOptionsModal in modal')
    // console.log(this.props)

    return (
      <Modal size="lg" centered={true} isOpen={this.props.showFoodAppsExistingOrderMenuOptionsModal} toggle={this.toggle} className={this.props.className} >
        {/* <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg"> */}
        {/* <ModalHeader toggle={this.toggle}>Select your option</ModalHeader> */}
        <ModalBody className="kitchenOptionsModalBody">
          <FoodAppsExistingsOrderMenuOptionsModalBody
            toggle={this.toggle}
            handleOnClickViewExistingFoodAppsOrderOrder={this.props.handleOnClickViewExistingFoodAppsOrderOrder}
            handleOnClickDeleteExistingFoodAppsOrderOrder={this.props.handleOnClickDeleteExistingFoodAppsOrderOrder}
            handleOnClickCancelExistingFoodAppsOrderOrder={this.props.handleOnClickCancelExistingFoodAppsOrderOrder}
          />
        </ModalBody>
        {/* <ModalFooter>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.applyDiscount}>Done</Button>
                        <Button color="warning" className="btnNotPadModalButton" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter> */}
      </Modal>
    );
  }
};

export default FoodAppsExistingsOrderMenuOptionsModal;